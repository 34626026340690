import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Page from '../layout/Page';
import Container from '../layout/Container';
import Link from '../components/link/Link';

const seo = {
  page_title: '404',
  page_description: 'Page Not Found',
  page_keywords: 'page, not, found, 404',
  page_image: {
    url: '/images/social.png'
  }
};

const NotFoundPage = ({ location }) => (
  <Page
    seo={{
      ...seo,
      pathname: location.pathname
    }}
  >
    <Container size={100} isCenter>
      <Typography variant="h1" align="center" gutterBottom>
        404
      </Typography>
      <Typography variant="h3" align="center" gutterBottom>
        Page Not Found
      </Typography>
      <div style={{ textAlign: 'center' }}>
        <Link to="/">
          <Button variant="contained" color="primary">
            Back To Home
          </Button>
        </Link>
      </div>
    </Container>
  </Page>
);

NotFoundPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default NotFoundPage;
